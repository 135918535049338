import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAppStore } from "@/store/app";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Keyboard, { type KeyboardHandlerEvent } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import * as yup from "yup";

import Box from "@/components/box";
import Eliza from "@/components/eliza";
import Header from "@/components/header";
import LogoOCB from "@/components/logo-ocb";
import { Checkbox } from "@/components/ui/checkbox";
import { cnpjValidation } from "@/lib/utils";
import { searchCooperado } from "@/services/content";
import type { SignUpCredentials, SignUpForm } from "@/types/auth";
import { AiOutlineFileSearch, AiOutlineMail } from "react-icons/ai";
//validate telefone on (00) 00000-0000 format
const phoneRegExp = /^\([0-9]{2}\) [0-9]{5}-[0-9]{4}$/;
const cnpjRegExp = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

const schema = yup.object().shape({
  cnpj: yup
    .string()
    .required("CNPJ é obrigatório")
    .matches(cnpjRegExp, "CNPJ inválido")
    .default(""),
  telefone: yup
    .string()
    .required("Telefone é obrigatório")
    .matches(phoneRegExp, "Telefone inválido")
    .default(""),
  lgpd: yup
    .boolean()
    .oneOf([true], "Você precisa aceitar os termos de privacidade")
    .default(false),
  newsletter: yup.boolean().default(false),
});

const defaultKeyboardLayout = {
  default: ["1 2 3", "4 5 6", "7 8 9", " 0 {backspace}"],
};

const getRamoID = (ramo: string) => {
  switch (ramo) {
    case "AGROPECUÁRIO":
      return "agro";
    case "CONSUMO":
      return "consumo";
    case "CRÉDITO":
      return "credito";
    case "SAÚDE":
      return "saude";
    case "TRANSPORTE":
      return "transporte";
    case "INFRAESTRUTURA":
      return "infra";
    default:
      return "";
  }
};

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [coopInfo, setCoopInfo] = useState<any>();

  const keyboardRef = useRef(null) as MutableRefObject<KeyboardHandlerEvent>;

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
  });
  const { login, signUp, appName } = useAppStore();

  const handleSignIn = useCallback(
    async (data: SignUpForm) => {
      clearErrors();

      if (formStep < 2) {
        setFormStep(formStep + 1);
        return;
      }

      setLoading(true);

      if (!data.lgpd) {
        setError("lgpd", {
          message: "Você precisa aceitar os termos de privacidade",
        });
        setLoading(false);
        return;
      }

      const payload: SignUpCredentials = {
        ...data,
        name: coopInfo?.coop,
        cnpj: `${parseInt(data.cnpj?.replace(/\D/g, "") || "0")}`,
        ramo: getRamoID(coopInfo?.ramo),
      };

      try {
        const result: any = await signUp(payload);
        await login({ username: `${result._id}`, password: `${payload.cnpj}` });

        setTimeout(() => {
          navigate("/");
        }, 1000);
      } catch (err: any) {
        handleSignInError(err);
        setLoading(false);
      }
    },
    [formStep],
  );

  const handleSignInError = useCallback(
    async (err: any) => {
      console.log(err);
      if (!coopInfo) {
        setFormStep(0);
        return;
      }
      setLoading(false);
      if (err.cnpj) {
        setFormStep(0);
      } else if (err.telefone) {
        setFormStep(1);
      } else if (formStep < 2 && err.lgpd) {
        setFormStep(2);
      }
    },
    [formStep, coopInfo],
  );

  const handleBack = useCallback(() => {
    if (formStep > 0) {
      setFormStep(formStep - 1);
    } else {
      navigate("/");
    }
  }, [formStep]);

  const applyMask = (value: string, mask: string) => {
    let i = 0;

    return mask.replace(/#/g, () => value[i++] || "_");
  };

  const search = async (cnpj: string) => {
    setLoading(true);
    searchCooperado(cnpj).then((data) => {
      if (data) {
        setCoopInfo(data);
      } else {
        setError("cnpj", { message: "CNPJ não encontrado" });
      }
      setLoading(false);
    });
  };

  const onChange = (e: any) => {
    const value = e.target ? e.target.value : e;
    const cleanValue = value.replace(/\D/g, "");

    switch (formStep) {
      case 0:
        setValue("cnpj", applyMask(cleanValue, "##.###.###/####-##"));
        if (cleanValue.length === 14) {
          clearErrors();
          if (cnpjValidation(cleanValue)) {
            search(cleanValue);
          } else {
            setError("cnpj", { message: "CNPJ inválido" });
          }
        }
        break;
      case 1:
        setValue("telefone", applyMask(cleanValue, "(##) #####-####"));
        break;
      default:
        break;
    }
  };

  const onKeyDown = useCallback(
    (e: KeyboardHandlerEvent) => {
      const value = e.target.value;
      const cleanValue = value.replace(/\D/g, "");

      if (e.key === "Backspace") {
        if (cleanValue.length === 0 && formStep === 1) {
          setFormStep(formStep - 1);
          (
            document.querySelector(`input[data-step="0"]`) as HTMLInputElement
          )?.focus();
        } else {
          e.preventDefault();
          e.target.value = cleanValue.slice(0, -1);
          onChange(e);
          return false;
        }
      } else if (
        e.key.match(/[a-z]/i) &&
        e.key.length === 1 &&
        !e.metaKey &&
        !e.ctrlKey &&
        !e.altKey &&
        !e.shiftKey
      ) {
        e.preventDefault();
        return false;
      }
    },
    [formStep],
  );

  useEffect(() => {
    if (keyboardRef.current) {
      keyboardRef.current.clearInput("keyboard");
    }

    const input = document.querySelector(
      `input[data-step="${formStep}"]`,
    ) as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }, [formStep, keyboardRef]);

  useEffect(() => {
    document.body.classList.add("bg-internal");
    setLoading(false);
  }, []);

  return (
    <div className="flex flex-col gap-2 items-center sm:justify-center min-h-full sm:h-full w-full absolute top-0 left-0 pt-20 pb-6 sm:pb-0 sm:pt-0 sm:overflow-hidden">
      <Header back onClick={handleBack} />
      <form
        onSubmit={handleSubmit(handleSignIn, handleSignInError)}
        className="w-[90vw] max-w-[670px] xl:max-w-[800px] 2xl:max-w-[670px]"
      >
        <Box className="relative flex flex-col items-center gap-3 sm:gap-8 px-0 pb-0 md:mb-12 2xl:mb-0 md:pb-16 xl:pb-12 2xl:pb-20">
          {formStep < 2 ? (
            <div className="w-full px-4 flex flex-col gap-2 sm:gap-6 text-center">
              <h1 className="text-brand-blue leading-none sm:text-2xl font-bold">
                Para começar, informe o CNPJ
                <br />
                da sua coop e seu telefone.
              </h1>

              <div className="relative" onClick={() => setFormStep(0)}>
                <label
                  className="text-xl font-bold text-brand-blue"
                  htmlFor="cnpj"
                >
                  CNPJ DA MATRIZ
                </label>
                <Input
                  data-step="0"
                  aria-invalid={errors.cnpj ? "true" : "false"}
                  className={`aria-[invalid=true]:bg-red-500 mx-auto mt-2 sm:w-[500px] border-2 border-white bg-brand-blue text-xl sm:text-3xl h-auto leading-none p-3 rounded-full text-center text-white pointer-events-none xl:pointer-events-auto ${
                    formStep === 0
                      ? "ring-2 sm:ring-4 ring-brand-blue ring-opacity-50"
                      : ""
                  }`}
                  {...register("cnpj")}
                  placeholder="00.000.000/0000-00"
                  type="text"
                  autoComplete="off"
                  onFocus={() => setFormStep(0)}
                  onKeyDown={onKeyDown}
                  onChange={onChange}
                />
                {errors.cnpj ? (
                  <small className="bg-red-800 text-white p-2 sm:p-1  rounded-full sm:absolute border-2 border-white -bottom-4 left-0 right-0 mx-auto sm:w-1/2">
                    {errors.cnpj?.message}
                  </small>
                ) : null}
              </div>

              <div onClick={() => setFormStep(1)}>
                <label
                  className="text-xl font-bold text-brand-blue"
                  htmlFor="telefone"
                >
                  TELEFONE (COM DDD)
                </label>
                <Input
                  data-step="1"
                  aria-invalid={errors.telefone ? "true" : "false"}
                  className={`aria-[invalid=true]:bg-red-500 mx-auto mt-2 sm:w-[500px] border-2 border-white bg-brand-blue text-xl sm:text-3xl h-auto leading-none p-3 rounded-full text-center text-white pointer-events-none xl:pointer-events-auto ${
                    formStep === 1
                      ? "ring-4 ring-brand-blue ring-opacity-50"
                      : ""
                  }`}
                  {...register("telefone")}
                  placeholder="(00) 00000-0000"
                  autoComplete="off"
                  type="text"
                  onFocus={() => setFormStep(1)}
                  onKeyDown={onKeyDown}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}

          {formStep === 2 ? (
            <div className="flex flex-col gap-1 px-2 sm:px-8 xl:px-0 2xl:px-8">
              <h1 className="text-brand-blue leading-none sm:text-2xl xl:text-xl 2xl:text-2xl font-bold text-center">
                Eu vou precisar guardar seus dados
                <br className="xl:hidden 2xl:block" /> para registrar sua
                participação, OK?
              </h1>
              <hr className="my-2 sm:my-4 xl:my-2 2xl:my-2 w-1/2 mx-auto" />

              <div>
                <h2 className="font-bold text-xl sm:text-3xl text-center leading-none">
                  {coopInfo?.coop}
                </h2>
                <p className="font-bold sm:text-2xl text-center leading-none mb-2">
                  {getValues("telefone")}
                </p>
              </div>

              <div className="border rounded-lg text-sm sm:text-md font-normal bg-white space-y-5 p-5 h-[25vh] min-h-[200px] xl:min-h-0 xl:h-[25vh] overflow-y-scroll">
                <p>
                  <strong>AVISO DE PRIVACIDADE</strong>
                </p>
                <p>
                  <strong>Identificação</strong>
                </p>
                <p>
                  O&nbsp;<strong>SISTEMA OCB&nbsp;</strong>composto pela&nbsp;
                  <strong>
                    ORGANIZAÇÃO DAS COOPERATIVAS BRASILEIRAS – OCB NACIONAL,
                  </strong>{" "}
                  inscrita no CNPJ sob nº&nbsp;63.057.822/0001-29,
                  <strong>
                    {" "}
                    SERVIÇO NACIONAL DE APRENDIZAGEM DO COOPERATIVISMO –
                    SESCOOP,
                  </strong>{" "}
                  inscrito no CNPJ sob nº&nbsp;03.087.543/0001-86&nbsp;e pela
                  <strong>
                    {" "}
                    CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS - CNCOOP,&nbsp;
                  </strong>
                  inscrita no CNPJ sob nº&nbsp;07.572.853/0001-47,
                  estabelecido&nbsp;no Setor de Autarquias Sul, Quadra 04, Bloco
                  I, Ed. Casa do Cooperativismo, Brasília/DF, é o responsável
                  por definir os motivos pelos quais os seus dados pessoais
                  serão utilizados.
                </p>
                <p>
                  <strong>Dados utilizados e para quais finalidades</strong>
                </p>
                <p>
                  Não coletaremos dados pessoais desnecessários e utilizaremos
                  os dados pessoais conforme especificado no resumo abaixo:
                </p>
                <p>Dados Pessoais Utilizados: Nome e e-mail;</p>
                <p>
                  Finalidades: 1) Possibilitar o acesso na plataforma “
                  <strong>Jornada Coop</strong>”
                  <i>
                    ; 2) Identificar se a cooperativa do usuário utiliza os
                    produtos/serviços do Sistema OCB; 3) Emitir cupom para
                    sorteio de brinde; 4) Enviar o cupom emitido ao usuário; 5)
                    Contatar os usuários acerca da “Jornada Coop” e/ou sorteio;
                    6) Enviar comunicações oficiais relacionadas com o Sistema
                    OCB.
                  </i>
                </p>
                <p>
                  <strong>Forma e duração da utilização dos seus dados</strong>
                </p>
                <p>
                  Os dados pessoais indicados neste Aviso serão coletados
                  através do formulário digital disponibilizado no site&nbsp;
                  <a href="http://www.jornada.coop.br">www.jornada.coop.br</a>.
                  Eles serão utilizados para gerar e enviar cupom para que o
                  usuário participe de sorteios promovidos por nós. Além disso,
                  caso você opte por receber nossas comunicações oficiais,
                  enviaremos e-mails divulgando nossas iniciativas em prol do
                  cooperativismo.
                </p>
                <p>
                  Manteremos os dados pessoais durante o período necessário para
                  o atendimento das finalidades indicadas neste Aviso, sendo que
                  após, descartaremos os dados pessoais coletados, exceto
                  aqueles que a conservação é necessária para atender obrigações
                  legais e/ou para possibilitar o exercício de direitos em
                  processo judicial, administrativo e/ou arbitral. Caso você
                  indique que deseja receber nossas comunicações, manteremos
                  seus dados enquanto você não efetuar o seu descadastramento de
                  nosso mailing.
                </p>
                <p>
                  <strong>Sobre o compartilhamento dos dados pessoais</strong>
                </p>
                <p>
                  Os dados pessoais coletados serão compartilhados apenas com as
                  pessoas essenciais ao desenvolvimento da <i>atividade</i>, ou
                  seja <strong>(i)</strong> com a empresa responsável pelo
                  desenvolvimento da gamificação e armazenamento dos dados
                  coletados; <strong>(ii)</strong> com o provedor de serviço de
                  correio eletrônico (e-mail) e e-mail marketing para envio de
                  comunicações oficiais; e <strong>(iii)</strong> com os
                  colaboradores dos nossos setores envolvidos no desenvolvimento
                  da iniciativa.
                </p>
                <p>
                  Os nossos colaboradores e parceiros estão contratualmente
                  obrigados a adotar padrões de segurança no uso dos dados
                  pessoais de acordo com a Lei Geral de Proteção de Dados
                  Pessoais (LGPD).&nbsp;
                </p>
                <p>
                  <strong>Transferência internacional de dados</strong>
                </p>
                <p>
                  Informamos que os dados pessoais inseridos no formulário serão
                  enviados e armazenados no datacenter da subcontratada Digital
                  Ocean que está localizado em Nova York nos Estados Unidos da
                  América. Desta forma, ao aceitar os termos deste aviso, você
                  consente com a transferência internacional de suas
                  informações. No entanto, você pode a qualquer tempo revogar
                  este consentimento, hipótese em que removeremos os seus dados
                  do datacenter. Porém, isso impossibilitará a sua participação
                  na “<strong>Jornada Coop</strong>”.
                </p>
                <p>
                  <strong>Direitos dos titulares</strong>
                </p>
                <p>
                  A Lei Geral de Proteção de Dados Pessoais (LGPD) garante uma
                  série de direitos sobre os dados pessoais. Eles podem ser
                  exercidos encaminhando uma mensagem para&nbsp;
                  <a href="mailto:privacidade@ocb.coop.br">
                    <strong>privacidade@ocb.coop.br</strong>
                  </a>
                  <strong>.</strong>
                </p>
                <p>
                  De acordo com a lei, é possível: a) confirmar a existência de
                  uso dos dados pessoais; b) acessar os dados pessoais
                  utilizados, caso eles estejam sendo usados; c) corrigir dados
                  pessoais incompletos, incorretos ou desatualizados; d)
                  anonimizar (impedir a identificação através do dado pessoal),
                  bloquear ou eliminar os dados desnecessários, excessivos ou
                  utilizados em desacordo com a lei; e) obter informações sobre
                  o compartilhamento dos dados com terceiros; f) se opor aos
                  tratamentos de dados pessoais realizados; g) revogar o
                  consentimento previamente concedido para tratamento de seus
                  dados; e/ou h) nos casos em que não atendermos adequadamente
                  suas solicitações, registrar reclamação perante a Autoridade
                  Nacional de Proteção de Dados – ANPD.&nbsp;
                </p>
                <p>
                  Se recebermos uma solicitação sua para exercer qualquer um dos
                  direitos acima,&nbsp;podemos pedir que você confirme sua
                  identidade antes&nbsp;de atendermos a solicitação, com o
                  objetivo&nbsp;de garantir que seus dados se
                  mantenham&nbsp;protegidos em segurança.
                </p>
              </div>

              <div className="flex flex-col xl:flex-row 2xl:flex-col gap-1">
                <div className="border px-4 py-3 sm:py-4 rounded-lg">
                  <label className=" flex items-center gap-4 xl:gap-2 2xl:gap-4">
                    <Checkbox
                      className={`aria-[invalid=true]:bg-red-500`}
                      onCheckedChange={(e) => {
                        setValue("lgpd", Boolean(e), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <span className="leading-none flex-1 text-xs sm:text-sm sm:leading-tight">
                      Li e estou de acordo com os termos de privacidade e uso de
                      dados pessoais .
                    </span>
                    <AiOutlineFileSearch className="w-6 h-6 sm:w-10 sm:h-10 xl:w-6 xl:h-6 2xl:w-10 2xl:h-10 text-brand-blue" />
                  </label>
                </div>

                <div className="border px-4 py-3 sm:py-4 rounded-lg">
                  <label className=" flex items-center gap-4 xl:gap-2 2xl:gap-4 just">
                    <Checkbox
                      onCheckedChange={(e) => {
                        setValue("newsletter", Boolean(e), {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <span className="leading-none flex-1 text-xs sm:text-sm sm:text-md sm:leading-tight">
                      Aceito receber contato sobre assuntos relacionados{" "}
                      {appName === "CapacitaCoop" ? "à " : "ao "}
                      {appName}
                    </span>
                    <AiOutlineMail className="w-6 h-6 sm:w-10 sm:h-10 xl:w-6 xl:h-6 2xl:w-10 2xl:h-10 text-brand-blue" />
                  </label>
                </div>
              </div>
            </div>
          ) : null}

          <Button
            type="submit"
            disabled={loading}
            className="bg-brand-blue mb-3 text-lg sm:text-3xl h-auto leading-none pt-3 pb-2 px-6 rounded-full md:absolute md:-bottom-10 md:left-0 md:right-0 sm:mx-auto sm:w-[300px]"
          >
            {loading ? "CARREGANDO..." : "CONTINUAR"}
          </Button>

          {formStep < 2 ? (
            <div className="xl:hidden w-full">
              <Keyboard
                disableButtonHold={true}
                layout={defaultKeyboardLayout}
                buttonTheme={[
                  {
                    class: "opacity-0 pointer-events-none",
                    buttons: " ",
                  },
                  {
                    class: "max-w-[33%]",
                    buttons: "{backspace}",
                  },
                  {
                    class: "max-w-[100px]",
                    buttons: "coop",
                  },
                  {
                    class: "font-bold text-2xl font-sans text-brand-blue",
                    buttons: "1 2 3 4 5 6 7 8 9 0 {backspace}",
                  },
                ]}
                display={{
                  "{space}": "___",
                  "{backspace}": "⌫",
                }}
                onChange={onChange}
                inputName="keyboard"
                keyboardRef={(r) => {
                  keyboardRef.current = r;
                }}
              />
            </div>
          ) : null}
        </Box>
      </form>

      <Eliza
        name="stars"
        className="hidden sm:block absolute h-[50vh] xl:h-[90vh] 2xl:h-[60vh] -left-10 xl:left-5 2xl:left-20 -bottom-56 sm:-bottom-72 xl:-bottom-48 animate-in slide-in-from-left-20"
      />
      <LogoOCB
        white
        className="mt-2 sm:absolute left-0 right-0 mx-auto sm:bottom-5"
      />
    </div>
  );
}
