import { useAppStore } from "@/store/app";
import { useEffect, useMemo } from "react";

interface RootProps {
  children: React.ReactNode;
}

export default function Root({ children }: RootProps) {
  const { user } = useAppStore();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        document.body.classList.add("bg-internal");
      });
    }
  }, [user]);

  return useMemo(() => {
    if (!user) {
      return (
        <div className="p-8 flex">
          <h3 className="shadow text-2xl p-3 px-6 rounded-full bg-white mx-auto">
            Carregando...
          </h3>
        </div>
      );
    }

    return <div className="w-screen overflow-x-hidden">{children}</div>;
  }, [children, user]);
}
