import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import ThemeProvider from "./context/theme-provider";
import "./global.css";
import { FunifierClientSetup } from "./lib/funifier-client";
import { StartMonitoring } from "./lib/monitoring";
import router from "./routes";

const root = document.getElementById("root");
if (!root) throw new Error("Root element not found");

StartMonitoring();
FunifierClientSetup();

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>,
);
