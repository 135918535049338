import { _env } from "@/lib/funifier-env";
import { User } from "@/types/user";
import { funifierApi } from "./api";

export async function fetchUser() {
  try {
    const { data } = await funifierApi.get("/player/me/status");
    const user = data as User;
    return user;
  } catch (err) {
    // console.log(err.response.data.message);
  }
}

export async function refreshUserStatus() {
  try {
    await funifierApi.put("/player/me/status", {});
    const player = await fetchUser();
    return player;
  } catch (err) {
    console.log(err);
  }
}
