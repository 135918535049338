import { funifierApi } from "./api";

export async function getMenu() {
  try {
    const { data } = await funifierApi.post("/find/menu", {});
    if (data && Array.isArray(data) && data.length > 0) {
      return data[0].value;
    }
    return [];
  } catch (err) {
    return [];
  }
}
