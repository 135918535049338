import { getSessionCookies } from "./cookies";
import { _env } from "./funifier-env";

let FunifierWidgetRenderMemo: Map<string, boolean>;
FunifierWidgetRenderMemo = new Map<string, boolean>().set("widgetId", true);
/**
 * Represents the Funifier event constants.
 */
export const FunifierEvent = {
  READY: "funifier:ready",
};

/**
 * Loads the Funifier client library asynchronously.
 * @returns A promise that resolves to `true` when the Funifier client library is loaded.
 */
export function FunifierClientLoad() {
  return new Promise((resolve) => {
    if (_env.Funifier !== undefined) {
      resolve(true);
    } else {
      const script = document.createElement("script");
      script.src = "https://client2.funifier.com/v3/funifier.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
    }
  });
}

/**
 * Sets up the Funifier client by initializing it with the provided API key and service.
 * It also authenticates the client and dispatches a custom event when the client is ready.
 * @throws {Error} If Funifier is not configured or not loaded.
 */
export async function FunifierClientSetup() {
  try {
    const apiKey: string = _env.FUNIFIER_ENV?.apikey || "";
    const service: string = _env.FUNIFIER_ENV?.service || "";

    FunifierWidgetRenderMemo = new Map<string, boolean>();

    if (!apiKey || !service) throw new Error("Funifier not configured");

    await FunifierClientLoad();

    if (!_env.Funifier) throw new Error("Funifier not loaded");

    _env.Funifier?.init({ apiKey, service }, () => {
      FunifierAuthenticate();
      document.dispatchEvent(new CustomEvent(FunifierEvent.READY));
    });
  } catch (err) {
    console.warn(`ATTENTION: ${(err as Error).message}`);
  }
}

/**
 * Authenticates the Funifier client.
 * If the Funifier client is not loaded or the authorization is not set, it tries to set the authorization using the access token from the session cookies.
 */
export async function FunifierAuthenticate() {
  try {
    if (!_env.Funifier) throw new Error("Funifier not loaded");
    if (!_env.Funifier?.auth.getAuthorization()) {
      const session = getSessionCookies();
      _env.Funifier?.auth.setAuthorization(`Bearer ${session.access_token}`);
    }
  } catch (err) {
    console.warn(`ATTENTION: ${(err as Error).message}`);
  }
}

/**
 * Renders the Funifier widget with the specified ID.
 *
 * @param id - The ID of the widget to render.
 * @param callback - Optional callback function to handle errors and data returned by the widget.
 * @returns void
 * @throws Error if Funifier is not loaded or an error occurs during rendering.
 */
export function FunifierWidgetRender(
  id: string,
  callback?: (err: unknown, data: unknown) => void,
) {
  try {
    if (!_env.Funifier) throw new Error("Funifier not loaded");
    if (FunifierWidgetRenderMemo.get(id)) {
      return;
    }
    FunifierWidgetRenderMemo.set(id, true);
    FunifierAuthenticate();
    _env.Funifier?.widget.render(
      {
        widget: id,
        selector: `#${id}__container`,
        bind: "replace",
        player: "me",
      },
      (err, data) => {
        FunifierWidgetRenderMemo.delete(id);
        if (callback) callback(err, data);
      },
    );
  } catch (err) {
    throw new Error(`${(err as Error).message}`);
  }
}
