import type { PropsWithChildren } from "react";

interface BoxProps extends PropsWithChildren {
  className?: string;
}
export default function Box({ children, className = "" }: BoxProps) {
  return (
    <div
      className={`bg-white p-3 sm:p-8 rounded-xl border-2 border-gray-5 ring-1 ring-gray-300 shadow-md ${className}`}
    >
      {children}
    </div>
  );
}
