import {
  clearStorage,
  getSessionCookies,
  setSessionCookies,
} from "@/lib/cookies";
import { actionLog } from "@/services/actions";
import { signIn, signUp } from "@/services/auth";
import { getMenu } from "@/services/settings";
import { refreshUserStatus } from "@/services/user";
import type {
  AppSession,
  LoginCredentials,
  SignUpCredentials,
} from "@/types/auth";
import type { MenuItem } from "@/types/settings";
import type { User } from "@/types/user";
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { create } from "zustand";
import { devtools } from "zustand/middleware";

// Define types for our store state
interface AppStore {
  appTheme: string;
  appName: string;
  appType: string;
  appSession: AppSession | undefined;
  user: User | undefined;
  menu: MenuItem[] | undefined;
  logout: () => void;
  isLoggedIn: () => boolean;
  setAppTheme: (theme: string) => void;
  setAppSession: () => void;
  setUser: (user: User) => void;
  refreshUser: () => Promise<void>;
  loginWithToken: (access_token: string) => Promise<User | undefined>;
  login: ({ username, password }: LoginCredentials) => Promise<boolean>;
  signUp: (payload: SignUpCredentials) => Promise<any>;
  restoreSession: () => Promise<void>;
  loadMenu: () => Promise<void>;
}
export const useAppStore = create<AppStore>()(
  devtools((set, get) => ({
    appTheme: "",
    appName: "AvaliaCoop",
    appType: "DIAGNÓSTICO",
    user: undefined,
    appSession: undefined,
    menu: undefined,
    isLoggedIn: () => !!get().user,
    setAppTheme: (theme) =>
      set({
        appTheme: theme,
        appName: theme === "capacitacoop" ? "CapacitaCoop" : "AvaliaCoop",
        appType: theme === "capacitacoop" ? "PLATAFORMA" : "DIAGNÓSTICO",
      }),
    setAppSession: () => {
      const session = getSessionCookies();
      if (session) {
        setSessionCookies(session);
      }
    },
    setUser: (user) => set({ user }),
    refreshUser: async () => {
      const user = await refreshUserStatus();
      window.postMessage("FUNIFIER_UPDATE", "*");
      set({ user });
    },
    loginWithToken: async (access_token) => {
      console.log("loginWithToken", access_token);
      return undefined;
    },
    login: async ({ username, password }) => {
      const session = await signIn({ username, password });
      if (session) {
        setSessionCookies(session);
        await actionLog({
          actionId: "login",
          userId: username,
          attributes: { theme: get().appName },
        });
        return true;
      }
      return false;
    },
    signUp: async (payload: SignUpCredentials) => {
      const result = await signUp(payload);
      return result;
    },
    logout: () => {
      clearStorage();
      set({ user: undefined, appSession: undefined });
    },
    restoreSession: async () => {
      const session = getSessionCookies();
      if (!get().appSession && session) {
        set({ appSession: session });
        if (!get().user) {
          const user = await refreshUserStatus();
          set({ user });
        }
      }
    },
    loadMenu: async () => {
      const menu = await getMenu();
      set({ menu });
    },
  })),
);
