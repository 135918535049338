import { useAppStore } from "@/store/app";
import avaliacoop from "../assets/images/avaliacoop.svg";
import capacitacoop from "../assets/images/capacitacoop.svg";

interface LogoDiagnosticoProps {
  white?: boolean;
  className?: string;
}

const logos: Record<string, string> = {
  avaliacoop,
  capacitacoop,
};

export default function LogoDiagnostico({ className }: LogoDiagnosticoProps) {
  const { appTheme } = useAppStore();

  return (
    <img
      src={logos[appTheme]}
      className={`pointer-none ${className}`}
      alt={appTheme}
    />
  );
}
