// PrivateRoute.tsx

import { NavBar } from "@/components/navbar";
import { Toaster } from "@/components/ui/sonner";
import { NotificationProvider } from "@/context/notification-provider";
import { getSessionCookies } from "@/lib/cookies";
import Root from "@/pages/root";
import { useAppStore } from "@/store/app";

import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
  const { restoreSession } = useAppStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const session = getSessionCookies();
    if (!session) {
      const redirect = encodeURIComponent(location.pathname);
      navigate(
        `/welcome${
          redirect && redirect !== "%2F" ? `?redirect=${redirect}` : ""
        }`,
      );
    }
    if (session) {
      restoreSession();
    }
  }, [restoreSession, navigate, location]);

  return (
    <NotificationProvider>
      <Root>
        <NavBar />
        <main className="p-6">
          <Outlet />
        </main>
      </Root>
      <Toaster />
    </NotificationProvider>
  );
};

export default PrivateRoute;
