import Login from "@/pages/auth/login";
import Contact from "@/pages/contact";
import SimpleDashboard from "@/pages/dashboard/simple";
import Download from "@/pages/download";
import Welcome from "@/pages/welcome";
import { createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./private-route";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <SimpleDashboard />,
      },
      {
        path: "/saiba-mais",
        element: <Contact />,
      },
      {
        path: "*",
        element: <Welcome />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/download/:id",
    element: <Download />,
  },
]);

export default router;
