import iconCheck from "@/assets/icon/check.svg";
import iconUser from "@/assets/icon/users.svg";

import iconCheckPdf from "@/assets/images/pdf/check.png";
import iconUserPdf from "@/assets/images/pdf/users.png";

import { Button } from "@/components/ui/button";
import { actionLog } from "@/services/actions";
import { useNavigate } from "react-router-dom";

interface CapacitaCoopProps {
  data: any;
}

export const CONTENT = [
  {
    id: "capacita",
    title: "CapacitaCoop",
    description:
      "Identifica potencialidades, oportunidades e deficiências, resultando em um plano para acessar novos negócios.",
    kpis: [
      {
        label: "USUÁRIOS CADASTRADOS",
        field: "cadastros",
        icon: iconUser,
        iconPdf: iconUserPdf,
      },
      {
        label: "MATRICULAS CONCLUÍDAS",
        field: "conclusoes",
        icon: iconCheck,
        iconPdf: iconCheckPdf,
      },
    ],
  },
];

export default function CapacitaCoop({ data }: CapacitaCoopProps) {
  const navigate = useNavigate();

  function handleShowMore(id: string) {
    actionLog({
      actionId: "interaction",
      userId: "me",
      attributes: { area: id },
    });
    navigate("/saiba-mais");
  }

  return (
    <div>
      {!!data &&
        CONTENT?.map((item) => (
          <div
            key={item.id}
            className="flex items-center gap-3 text-center mb-8"
          >
            {item.kpis.map((kpi) => (
              <div
                className="border border-brand-orange flex-1 rounded-xl gap-2 p-3 flex flex-col items-center"
                key={kpi.field}
              >
                <img src={kpi.icon} alt="" />
                <span>{kpi.label}</span>
                <p className="text-5xl font-bold text-brand-orange">
                  {data[item.id] ? data[item.id][kpi.field] : 0}
                </p>
              </div>
            ))}
          </div>
        ))}

      <div className="text-center flex flex-col gap-3 mb-4">
        <h3 className="text-xl sm:text-2xl xl:text-3xl font-bold text-brand-blue leading-none">
          A CapacitaCoop é aliada no desenvolvimento contínuo das cooperativas.
        </h3>

        <p className="sm:text-lg leading-none font-inter">
          Com mais de <b>200 cursos online</b>, a plataforma oferece o
          conhecimento necessário para impulsionar o sucesso e a eficiência das
          cooperativas brasileiras.
        </p>

        <p className="sm:text-lg leading-none font-inter">
          De temas de governança e gestão à adoção de tecnologias inovadoras, os
          cursos são <b>desenvolvidos para capacitar as equipes</b>, melhorar os
          resultados da organização e criar impacto positivo na comunidade.
        </p>
      </div>

      <div className="flex justify-center">
        <Button
          type="button"
          onClick={() => handleShowMore("saiba-mais-capacita")}
          className="w-full bg-brand-blue mb-3 text-lg sm:text-3xl h-auto leading-none pt-3 pb-2 px-6 rounded-full xl:absolute md:-bottom-10 md:left-0 md:right-0 sm:mx-auto sm:w-[300px]"
        >
          Quero saber mais!
        </Button>
      </div>
    </div>
  );
}
