import LogoOCB from "@/components/logo-ocb";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react";

import Box from "@/components/box";
import LogoDiagnostico from "@/components/logo-diagnostico";
import { Button } from "@/components/ui/button";
import { getResults, getUserByCode } from "@/services/content";
import { useAppStore } from "@/store/app";
import { useParams } from "react-router-dom";
import AvaliaCoopDocument from "./avaliacoop-document";
import CapacitaCoopDocument from "./capacitacoop-document";

export default function Download() {
  const { appName, appTheme } = useAppStore();
  const params = useParams();

  const [results, setResults] = useState<any>();
  const [loadingData, setLoadingData] = useState(true);

  const loadData = useCallback(async () => {
    const user = await getUserByCode(params.id, appTheme);
    const data = await getResults(
      appTheme.replace("coop", ""),
      user?.extra?.cnpj,
      user?.extra?.ramo,
    );

    setLoadingData(false);

    if (appTheme.includes("avaliacoop")) {
      if (
        data?.negocios ||
        data?.identidade ||
        data?.gestao ||
        data?.desempenho ||
        data?.esg
      ) {
        setResults(data);
      }
    } else {
      if (data?.capacita) {
        setResults(data);
      }
    }
  }, [params, appTheme]);

  useEffect(() => {
    if (!appTheme) return;
    if (params.id) loadData();
  }, [params, loadData, appTheme]);

  useEffect(() => {
    document.body.classList.remove("bg-internal");
  }, []);

  return (
    <div className="absolute sm:overflow-hidden pt-10 sm:pt-0 top-0 left-0 w-full h-full flex flex-col sm:items-center justify-center">
      <Box className="mx-3 py-6 mb-4 text-center relative flex flex-col items-center gap-3">
        <LogoDiagnostico className="w-full max-w-[60vw]" />

        {/* <Button className="bg-brand-blue mt-4 sm:mt-0 text-lg sm:text-2xl 2xl:text-3xl h-auto leading-none pt-3 pb-2 px-6 rounded-full 2xl:absolute 2xl:-bottom-10 sm:left-0 sm:right-0 mx-auto w-1/2 sm:w-[300px]">
          <span>BAIXAR</span>
        </Button> */}
        {loadingData ? (
          <h1>Preparando PDF para download...</h1>
        ) : (
          <PDFDownloadLink
            document={
              appTheme === "avaliacoop" ? (
                <AvaliaCoopDocument data={results} />
              ) : (
                <CapacitaCoopDocument data={results} />
              )
            }
            fileName={`${appName}_${results.coop}.pdf`}
          >
            {({ loading }) =>
              loading ? (
                <h1>Preparando PDF para download...</h1>
              ) : (
                <Button className="rounded-full bg-brand-blue text-lg p-6">
                  Baixar relatório da sua coop
                </Button>
              )
            }
          </PDFDownloadLink>
        )}
      </Box>
      <LogoOCB
        white
        className="mt-4 sm:mt-2 w-52 sm:absolute sm:left-0 xl:left-auto sm:right-0 xl:right-36 2xl:right-56 mx-auto xl:m-0 sm:bottom-5"
      />
    </div>
  );
}
