import logo from "../assets/images/logo-ocb-dark.svg";
import logoWhite from "../assets/images/logo-ocb-light.svg";

interface LogoOCBProps {
  white?: boolean;
  className?: string;
}
export default function LogoOCB({ white, className }: LogoOCBProps) {
  return (
    <img
      src={white ? logoWhite : logo}
      className={`pointer-none ${className}  w-48 sm:w-52 2xl:w-[250px]`}
      alt="Sistema OCB"
    />
  );
}
