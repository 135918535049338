// /* eslint-disable react-hooks/exhaustive-deps */
// import { useAuth } from "context/AuthContext";
// import {
//   createContext,
//   ReactNode,
//   useContext,
//   useEffect,
//   useState,
// } from "react";

import { useAppStore } from "@/store/app";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "sonner";

type NotificationContextData = {};

interface NotificationContextProps {
  children: ReactNode;
}

export const NotificationContext = createContext({} as NotificationContextData);

function NotificationProvider({ children }: NotificationContextProps) {
  const [client, setClient] = useState<any>();
  const [message, setMessage] = useState<any>();
  const { refreshUser, appSession } = useAppStore();

  function connect() {
    try {
      if (client) {
        client.close();
      }
      const token = `Bearer ${appSession?.access_token}`;
      if (token) {
        const socketClient = new WebSocket(
          `wss://service2.funifier.com/ws/notification?authorization=${encodeURIComponent(
            token,
          )}`,
        );
        setClient(socketClient);

        setTimeout(() => {
          sendMessage({ type: "subscribe", channel: "user" });
        }, 5000);
      }
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  function disconnect() {
    if (client) {
      client.close();
    }
    setClient(undefined);
  }

  function onMessage(e: MessageEvent) {
    setMessage(JSON.parse(e.data));

    const data = JSON.parse(e.data);

    if (data.type === 0) {
      toast.success(`Você ganhou ${data.total} ${data.item}!`);
    } else if (data.type === 1) {
      toast.success("Você completou um desafio!");
    } else {
      toast.success("Nova conquista!");
    }

    refreshUser();
    // dispatchEvent(new CustomEvent("websocket-message", { detail: JSON.parse(e.data) }));
  }

  function sendMessage(message: any) {
    if (!client) return;
    client.send(JSON.stringify(message));
  }

  useEffect(() => {
    if (appSession) {
      connect();
    }
  }, [appSession]);

  useEffect(() => {
    if (client) {
      client.onmessage = onMessage;
    }
    return () => {
      if (client) {
        client.onmessage = null;
      }
    };
  }, [client]);

  return (
    <NotificationContext.Provider
      value={{
        sendMessage,
        message,
        setMessage,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
}

export { NotificationProvider, useNotification };
