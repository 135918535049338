import { AppSession, LoginCredentials, SignUpCredentials } from "@/types/auth";

import { _env } from "@/lib/funifier-env";
import { slugify } from "@/lib/utils";
import { apiPublicHeaders, funifierApi } from "./api";

export async function signIn(credentials: LoginCredentials) {
  try {
    const payload = {
      grant_type: "password",
      apiKey: _env.FUNIFIER_ENV?.apikey,
      ...credentials,
    };
    const { data } = await funifierApi.post("/auth/token", payload);
    return data as AppSession;
  } catch (err) {
    // console.log(err.response.data.message);
  }
}

export function registerUserRequest(user: any) {
  return funifierApi
    .put("/database/registration__c", user, {
      ...apiPublicHeaders,
    })
    .then((response) => {
      const result = response.data;
      // return result;
      if (result.status === 200) {
        return result;
      }
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

export async function signUp(payload: SignUpCredentials) {
  try {
    const username = `${payload.cnpj}_${slugify(payload.telefone, "_")}`;
    // const result = await registerUserRequest({_id:username, ...credentials, lottery: Math.floor(100000 + Math.random() * 900000)});
    const result = await registerUserRequest({
      _id: username,
      ...payload,
    });
    return result;
  } catch (e) {
    return null;
  }
}
