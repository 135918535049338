import axios from "axios";

import { _env } from "@/lib/funifier-env";
import { setupInterceptors } from "./interceptors";

export function getFunifierAPIClient() {
  const api = axios.create({
    baseURL: `${_env.FUNIFIER_ENV?.service}`,
  });

  return setupInterceptors(api);
}

// Create your custom API client here
