import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { AiFillHome } from "react-icons/ai";
import LogoDiagnostico from "./logo-diagnostico";

interface HeaderProps {
  onClick: () => void;
  back?: boolean;
}
export default function Header({ back, onClick }: HeaderProps) {
  return (
    <div className="z-20 top-0 left-0 w-full fixed flex items-center justify-between pt-4 sm:pt-6 md:pt-8 pl-4 sm:pl-6 md:pl-8">
      <button
        onClick={() => onClick()}
        type="button"
        className="flex items-center justify-center rounded-full p-2 sm:p-4 w-12 h-12 sm:w-20 sm:h-20 md:w-[88px] md:h-[88px] border border-opacity-10 bg-white"
      >
        {back ? (
          <ArrowLeftIcon className="w-full h-full" />
        ) : (
          <AiFillHome className="w-full h-full" />
        )}
      </button>
      <div className="bg-white p-2 sm:p-4 pl-4 sm:pl-8 rounded-tl-full rounded-bl-full shadow">
        <LogoDiagnostico className="h-8 sm:h-16 md:h-12 2xl:h-[72px]" />
      </div>
    </div>
  );
}
