interface FunifierClient {
  init: (
    params: { apiKey: string; service: string },
    callback: () => void,
  ) => void;
  track: (actionId: string, attributes: unknown) => void;
  widget: {
    render: (
      payload: unknown,
      callback: (err: unknown, data: unknown) => void,
    ) => void;
  };
  auth: {
    authenticate: (
      credentials: { grant_type: string; client_secret: string },
      callback: (err: unknown, data: unknown) => void,
    ) => void;
    setAuthorization: (token: string) => void;
    getAuthorization: () => string;
  };
}

interface FunifierWindowProps {
  Funifier?: FunifierClient;
  FUNIFIER_ENV?: {
    apikey: string;
    service: string;
  };
}

let _env = window as FunifierWindowProps;

if (!_env.Funifier) {
  _env = {
    FUNIFIER_ENV: {
      apikey: import.meta.env.VITE_APP_APIKEY,
      service: import.meta.env.VITE_APP_API_URL,
    },
  };
}

export { _env };
