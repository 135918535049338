import { AppSession } from "@/types/auth";
import { Cookies } from "react-cookie";
import { COOKIE_EXPIRATION_TIME, COOKIE_PREFIX } from "./constants";

const cookies = new Cookies();

const SESSION_KEY = `${COOKIE_PREFIX}_session`;

export const setSessionCookies = (data: AppSession) => {
  setStorageItem("session", data);
};

export const getSessionCookies = () => {
  return getStorageItem("session");
};

export const clearSessionCookies = () => {
  cookies.remove(SESSION_KEY, { path: "/" });
};

export function setStorageItem(cookie: string, data: unknown) {
  const value = typeof data !== "object" ? JSON.stringify(data) : data;
  cookies.set(`${COOKIE_PREFIX}_${cookie}`, value, {
    maxAge: COOKIE_EXPIRATION_TIME,
    path: "/",
    sameSite: "none",
    secure: true,
  });
}

export function removeStorageItem(cookie: string) {
  cookies.remove(`${COOKIE_PREFIX}_${cookie}`);
}

export function clearStorage() {
  const regex = new RegExp(`^${COOKIE_PREFIX}_`);
  for (const cookie of Object.keys(cookies.getAll())) {
    if (regex.test(cookie)) {
      cookies.remove(cookie);
    }
  }
}

export function getStorageItem(cookie: string) {
  let result = cookies.get(`${COOKIE_PREFIX}_${cookie}`);
  try {
    result = JSON.parse(result);
  } catch (error) {
    //return error;
  }
  return result;
}
