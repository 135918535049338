import center from "../assets/images/eliza-center.svg";
import home from "../assets/images/eliza-home.svg";
import sadBig from "../assets/images/eliza-sad-big.svg";
import sad from "../assets/images/eliza-sad.svg";
import shipHappy from "../assets/images/eliza-ship-happy.svg";
import shipRight from "../assets/images/eliza-ship-right.svg";
import ship from "../assets/images/eliza-ship.svg";
import stars from "../assets/images/eliza-stars.svg";
import eliza from "../assets/images/eliza.svg";

const Sources: any = {
  default: eliza,
  home,
  center,
  sad,
  sadBig,
  ship,
  shipHappy,
  shipRight,
  stars,
};

interface ElizaProps {
  name: string;
  className?: string;
  style?: any;
}
export default function Eliza({ name, className, style }: ElizaProps) {
  return (
    <img
      src={Sources[name || "default"]}
      className={`pointer-none ${className}`}
      style={style}
    />
  );
}
