import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import { formatarFaturamento } from "@/lib/utils";

import diagnostico from "../../assets/images/pdf/avaliacoop.png";
import logo from "../../assets/images/pdf/logo-ocb.png";

import { CONTENT } from "../dashboard/avaliacoop";

// Register font
Font.register({
  family: "Intro",
  fonts: [
    {
      src: "/fonts/Inter-Regular.ttf",
    },
    {
      src: "/fonts/Inter-Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "/fonts/Inter-ExtraBold.ttf",
      fontWeight: 900,
    },
  ],
});

// const brandOrange = "#F57922";
const brandGreen = "#6D6D11";
const brandBlue = "#171C67";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 10,
    fontFamily: "Intro",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#f4f4f4",
    borderBottomStyle: "solid",
    gap: 20,
  },
  rowItem: {
    flexDirection: "row",
    padding: 5,
    gap: 10,
  },
  rowImage: {
    width: 52,
    height: 52,
  },
  section: {
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    flex: 1,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#333333",
  },
  results: {
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: "28%",
  },
  header: {
    margin: 5,
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "#171C67",
    fontSize: 26,
    fontWeight: 900,
  },
  kpiTitle: {
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "center",
  },
  kpiValue: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    color: brandBlue,
  },
  kpiItem: {
    margin: 2,
    padding: 6,
    borderWidth: 1,
    borderColor: "#e2e2e2",
    borderStyle: "solid",
    borderRadius: 13,
    textAlign: "center",
  },
});

type AvaliaCoopDocumentProps = {
  data: any;
};

// Create Document Component
const AvaliaCoopDocument = ({ data }: AvaliaCoopDocumentProps) => {
  function renderKpi(kpi: any, value: any) {
    if (kpi.type === "currency") {
      return <Text>R$ {formatarFaturamento(value)}</Text>;
    }

    if (kpi.type === "rank") {
      return <Text>{value}º</Text>;
    }

    if (kpi.type === "percentage") {
      return <Text>{value}%</Text>;
    }

    return <Text>{value}</Text>;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            paddingTop: 20,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={diagnostico}
            style={{
              height: "48px",
            }}
          />
        </View>
        <View style={styles.header}>
          <Text>{data.coop}</Text>
        </View>
        <View style={styles.row}>
          <View style={{ ...styles.section, paddingBottom: 0 }}>
            <Text style={styles.sectionTitle}>Diagnóstico</Text>
          </View>
          <View style={{ ...styles.results, paddingBottom: 0 }}>
            <Text style={{ ...styles.sectionTitle, textAlign: "center" }}>
              Resultados da sua coop
            </Text>
          </View>
        </View>
        {CONTENT?.map((item) => (
          <View key={item.id} style={styles.row}>
            <View style={styles.section}>
              <View style={styles.rowItem}>
                <View style={styles.rowImage}>
                  <Image src={item.iconPdf} />
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: brandGreen,
                      marginBottom: 2,
                    }}
                  >
                    {item.title}
                  </Text>
                  <Text style={{ fontSize: 11 }}>{item.description}</Text>
                </View>
              </View>
            </View>
            <View style={styles.results}>
              {data?.[item.id] ? (
                item.kpis.map((kpi) => (
                  <View key={kpi.field} style={styles.kpiItem}>
                    <Text style={styles.kpiTitle}>{kpi.label}</Text>
                    <Text style={styles.kpiValue}>
                      {renderKpi(kpi, data[item.id][kpi.field])}
                    </Text>
                  </View>
                ))
              ) : (
                <View>
                  <Text style={{ textAlign: "center" }}>---</Text>
                </View>
              )}
            </View>
          </View>
        ))}
        <View
          style={{
            margin: 10,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link href="https://somos.coop.br/">
            <Image
              src={logo}
              style={{
                width: "180px",
              }}
            />
          </Link>
        </View>
      </Page>
    </Document>
  );
};

export default AvaliaCoopDocument;
