import { _env } from "@/lib/funifier-env";
import { ActionLogRequest } from "@/types/action";
import { funifierApi } from "./api";

export async function actionLog(payload: ActionLogRequest) {
  try {
    await funifierApi.post("/action/log", payload);
  } catch (err) {
    // console.log(err.response.data.message);
  }
}
