import {
  AxiosDefaults,
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

import { clearStorage, getSessionCookies } from "@/lib/cookies";
// import { funifierApi } from "./api";

// interface IFailedRequestQueue {
//   onSuccess: (token: string) => void;
//   onFailure: (error: AxiosError) => void;
// }

// const isRefreshing = false;
// const failedRequestQueue: IFailedRequestQueue[] = [];

export function setAuthorizationHeader(
  request: AxiosDefaults | AxiosHeaders | AxiosRequestConfig,
  token: string,
) {
  if (request.headers && token) {
    request.headers.Authorization = `Bearer ${token.replace("Bearer ", "")}`;
  }
}

// function handleRefreshToken(refreshToken: string) {
//   isRefreshing = true;

//   funifierApi
//     .post("/refresh", { refreshToken })
//     .then((response) => {
//       const { token } = response.data;
//       setSession(response.data);
//       setAuthorizationHeader(funifierApi.defaults, token);
//       for (const request of failedRequestQueue) {
//         request.onSuccess(token);
//       }
//       failedRequestQueue = [];
//     })
//     .catch((error) => {
//       for (const request of failedRequestQueue) {
//         request.onFailure(error);
//       }
//       failedRequestQueue = [];

//       clearStorage();
//     })
//     .finally(() => {
//       isRefreshing = false;
//     });
// }

function onRequest(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  const session = getSessionCookies();
  config.headers["Content-Type"] = "application/json";
  if (!!session && session.access_token) {
    setAuthorizationHeader(config, session.access_token);
  }
  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> {
  if (error.response?.status === 401 && window.location.pathname !== "/login") {
    clearStorage();
    window.location.href = "/login";
  }
  return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
