import Eliza from "@/components/eliza";
import { Button } from "@/components/ui/button";
import { QRCodeCanvas } from "qrcode.react";

import LogoOCB from "@/components/logo-ocb";
import { useAppStore } from "@/store/app";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@/components/box";

export default function Contact() {
  const { logout, appName, appTheme } = useAppStore();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout();
    navigate("/welcome");
  }, [logout, navigate]);

  useEffect(() => {
    document.body.classList.remove("bg-internal");
  }, []);

  return (
    <div className="absolute sm:overflow-hidden pt-10 sm:pt-0 top-0 left-0 w-full h-full flex flex-col sm:items-center justify-center">
      <Box className="mx-3 text-center px-8 pb-0 sm:pb-0 2xl:pb-8">
        <div className="relative -top-8 2xl:-top-16 flex flex-col gap-1 sm:gap-3">
          <Eliza
            name="shipHappy"
            className="h-[20vh] xl:h-[25vh] 2xl:h-[30vh] relative animate-in slide-in-from-top-20"
          />
          <h1 className="text-3xl sm:text-6xl xl:text-4xl 2xl:text-6xl font-bold text-brand-blue">
            OBA!
          </h1>
          <p className="text-lg sm:text-xl leading-tight font-bold">
            Quer saber mais sobre
            <br />
            {appTheme.includes("avaliacoop") ? "o" : "a"} {appName}?
          </p>
          <p className="text-md sm:text-xl leading-tight">Acesse o QRCode</p>

          <QRCodeCanvas
            value={`https://www.somoscooperativismo.coop.br/solucoes/${appTheme}`}
            className="mx-auto h-28 xl:h-24 2xl:h-28 sm:h-auto"
          />

          {/* <img
            src={appTheme.includes("avaliacoop") ? qrcode : qrcodeCapacita}
            alt="QR Code"
            className="mx-auto h-28 xl:h-24 2xl:h-28 sm:h-auto"
          /> */}
          <Button
            onClick={() => handleLogout()}
            className="bg-brand-blue mt-4 sm:mt-0 text-lg sm:text-2xl 2xl:text-3xl h-auto leading-none pt-3 pb-2 px-6 rounded-full 2xl:absolute 2xl:-bottom-28 sm:left-0 sm:right-0 mx-auto w-1/2 sm:w-[300px]"
          >
            <span>ENCERRAR</span>
          </Button>
        </div>
      </Box>

      <LogoOCB
        white
        className="mt-4 sm:mt-2 w-52 sm:absolute sm:left-0 xl:left-auto sm:right-0 xl:right-36 2xl:right-56 mx-auto xl:m-0 sm:bottom-5"
      />
    </div>
  );
}
