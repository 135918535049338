import { apiPublicHeaders, funifierApi } from "./api";

export async function getContent(collection: string) {
  try {
    const { data } = await funifierApi.get(`database/${collection}`);
    if (Array.isArray(data)) {
      return data;
    }
    return [];
  } catch (err) {
    return [];
  }
}

export async function searchCooperado(cnpj?: string) {
  try {
    const isNumber = !isNaN(parseInt(cnpj?.valueOf() || "0"));
    const payload = {
      cnpj: `${isNumber ? parseInt(cnpj?.valueOf() || "0") : cnpj}`,
    };

    const searchHeaders: any = { ...apiPublicHeaders };
    // searchHeaders.headers.Range = "items=0-10000";
    const { data } = await funifierApi.post(
      `find/coop`,
      payload,
      searchHeaders,
    );
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function getUserByCode(code?: string, theme?: string) {
  try {
    if (!code) return null;

    const payload = {
      code,
      theme,
    };

    const searchHeaders: any = { ...apiPublicHeaders };
    // searchHeaders.headers.Range = "items=0-10000";
    const { data } = await funifierApi.post(
      "find/cnpj",
      payload,
      searchHeaders,
    );
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function getResults(context: string, cnpj: string, ramo: string) {
  try {
    const isNumber = !isNaN(parseInt(cnpj?.valueOf() || "0"));

    if (!isNumber) return;

    const payload = {
      cnpj: `${parseInt(cnpj?.valueOf())}`,
      ramo,
    };

    const { data } = await funifierApi.post(`find/${context}`, payload, { ...apiPublicHeaders });
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  } catch (err) {
    return null;
  }
}
