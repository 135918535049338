import Box from "@/components/box";
import { useCallback, useEffect, useState } from "react";

import LogoOCB from "@/components/logo-ocb";
import { Button } from "@/components/ui/button";
import { getResults } from "@/services/content";
import { useAppStore } from "@/store/app";
import { useNavigate } from "react-router-dom";
import AvaliaCoop from "./avaliacoop";
import CapacitaCoop from "./capacitacoop";

export default function SimpleDashboard() {
  const { user, appTheme, logout } = useAppStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState();

  const loadData = useCallback(async () => {
    if (!user) return;
    const data = await getResults(
      appTheme.replace("coop", ""),
      user?.extra?.cnpj,
      user?.extra?.ramo,
    );

    setLoading(false);

    if (appTheme.includes("avaliacoop")) {
      if (
        data?.negocios ||
        data?.identidade ||
        data?.gestao ||
        data?.desempenho ||
        data?.esg
      ) {
        setResults(data);
      }
    } else {
      if (data?.capacita) {
        setResults(data);
      }
    }
  }, [user, appTheme]);

  const handleLogout = useCallback(() => {
    logout();
    navigate("/welcome");
  }, [logout, navigate]);

  useEffect(() => {
    document.body.classList.add("bg-internal");
  }, []);

  useEffect(() => {
    if (!user) return;
    loadData();
  }, [user]);

  return (
    <div className="flex flex-col gap-2 items-center sm:justify-center min-h-full md:h-full w-full absolute top-0 left-0 pt-20 pb-6 md:pb-0 md:pt-0 md:overflow-hidden">
      <div className="w-[90vw] max-w-[670px]">
        {!loading ? (
          <Box className="relative p-1 sm:p-1">
            <div className="p-2 pb-0 sm:p-4 2xl:p-4 w-full flex flex-col items-center gap-4 sm:gap-6 mb-4 md:m-0 sm:max-h-[70vh] 2xl:max-h-auto sm:overflow-y-auto">
              {!results ? (
                <>
                  <h1 className="text-3xl sm:text-4xl mt-2 sm:mt-0 leading-none font-bold text-brand-blue flex items-center gap-2">
                    <span>QUE PENA!</span>
                  </h1>
                  <p className="text-lg sm:text-2xl text-center">
                    {appTheme.includes("capacitacoop") ? (
                      <span>
                        A coop <b className="text-brand-blue">{user?.name}</b>{" "}
                        não tem ainda nenhum aluno inscrito na plataforma
                      </span>
                    ) : (
                      <span>
                        A coop <b className="text-brand-blue">{user?.name}</b>{" "}
                        não participou de nenhuma avaliação
                      </span>
                    )}
                    .
                  </p>
                  <div>
                    {appTheme.includes("capacitacoop") ? (
                      <CapacitaCoop data={results} />
                    ) : (
                      <AvaliaCoop data={results} />
                    )}
                  </div>
                  <div className="text-center w-full mb-4">
                    <p className="leading-tight 2xl:leading-8  font-inter">
                      Conheça essas e outras soluções oferecidas pelo Sistema
                      OCB acessando:
                      <br />
                      <span className="underline text-branded-blue font-bold break-all">
                        https://somoscooperativismo.coop.br/solucoes/{appTheme}
                      </span>
                      <br />
                      ou entre em contato com a sua Organização Estadual
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center">
                    <h1 className="md:hidden sm:text-2xl">DIAGNÓSTICO</h1>
                    <h2 className="text-brand-blue text-xl sm:text-4xl font-bold">
                      {user?.name}
                    </h2>
                  </div>
                  <div>
                    {appTheme.includes("capacitacoop") ? (
                      <CapacitaCoop data={results} />
                    ) : (
                      <AvaliaCoop data={results} />
                    )}
                  </div>
                </>
              )}
              {appTheme.includes("avaliacoop") ? (
                <Button
                  onClick={() => handleLogout()}
                  className="bg-brand-blue sm:mb-0 text-lg sm:text-2xl 2xl:text-3xl h-auto leading-none pt-3 pb-2 px-6 rounded-full 2xl:absolute 2xl:-bottom-8 sm:left-0 sm:right-0 mx-auto w-1/2 sm:w-[300px]"
                >
                  <span>ENCERRAR</span>
                </Button>
              ) : null}
            </div>
          </Box>
        ) : null}
        <LogoOCB
          white
          className="mt-2 w-52 md:absolute sm:left-0 md:left-8 2xl:left-auto sm:right-0 md:right-0 2xl:right-56 mx-auto xl:m-0 md:bottom-5"
        />
      </div>
    </div>
  );
}
