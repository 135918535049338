import { Button } from "@/components/ui/button";
import { formatarFaturamento } from "@/lib/utils";
import { actionLog } from "@/services/actions";
import { useNavigate } from "react-router-dom";

import iconDesempenho from "@/assets/icon/kpi-desempenho.svg";
import iconEsg from "@/assets/icon/kpi-esg.svg";
import iconGestao from "@/assets/icon/kpi-gestao.svg";
import iconIdentidade from "@/assets/icon/kpi-identidade.svg";
import iconNegocios from "@/assets/icon/kpi-negocios.svg";

import iconDesempenhoPdf from "@/assets/images/pdf/kpi-desempenho.png";
import iconEsgPdf from "@/assets/images/pdf/kpi-esg.png";
import iconGestaoPdf from "@/assets/images/pdf/kpi-gestao.png";
import iconIdentidadePdf from "@/assets/images/pdf/kpi-identidade.png";
import iconNegociosPdf from "@/assets/images/pdf/kpi-negocios.png";

export const CONTENT = [
  {
    id: "negocios",
    icon: iconNegocios,
    iconPdf: iconNegociosPdf,
    title: "Negócios",
    description:
      "Organização mercadológica e sustentabilidade do negócio cooperativo.",
    kpis: [
      {
        label: "ÍNDICE",
        field: "indicador",
      },
    ],
  },
  {
    id: "identidade",
    icon: iconIdentidade,
    iconPdf: iconIdentidadePdf,
    title: "Identidade",
    description: "Conformidade legal e princípios cooperativistas.",
    kpis: [
      {
        label: "ÍNDICE PRINCÍPIOS",
        field: "principios",
        type: "percentage",
      },
      {
        label: "ÍNDICE GESTÃO",
        field: "gestao",
        type: "percentage",
      },
    ],
  },
  {
    id: "gestao",
    icon: iconGestao,
    iconPdf: iconGestaoPdf,
    title: "Governança e Gestão",
    description: "Melhoria contínua e boas práticas de Governança e Gestão.",
    kpis: [
      {
        label: "ÍNDICE GOVERNANÇA",
        field: "governanca",
        type: "percentage",
      },
      {
        label: "ÍNDICE GESTÃO",
        field: "gestao",
        type: "percentage",
      },
    ],
  },
  {
    id: "esg",
    icon: iconEsg,
    iconPdf: iconEsgPdf,
    title: "ESG",
    description: "Sustentabilidade com comprovação de impacto.",
    kpis: [
      {
        label: "ÍNDICE GERAL",
        field: "indicador",
      },
    ],
  },
  {
    id: "desempenho",
    icon: iconDesempenho,
    iconPdf: iconDesempenhoPdf,
    title: "Sistema Desempenho",
    description:
      "Resultados econômicos-financeiros e apoio na tomada de decisão.",
    kpis: [
      {
        label: "TOTAL ATIVOS (R$)",
        field: "indicador",
        type: "currency",
      },
      {
        label: "RANKING",
        field: "ranking",
        type: "rank",
      },
    ],
  },
];

interface AvaliaCoopProps {
  data: any;
}

export default function AvaliaCoop({ data }: AvaliaCoopProps) {
  const navigate = useNavigate();

  function handleShowMore(id: string) {
    actionLog({
      actionId: "interaction",
      userId: "me",
      attributes: { area: id },
    });
    navigate("/saiba-mais");
  }

  function renderKpi(kpi: any, value: any) {
    if (kpi.type === "currency") {
      return <span>R$ {formatarFaturamento(value)}</span>;
    }

    if (kpi.type === "rank") {
      return <span>{value}º</span>;
    }

    if (kpi.type === "percentage") {
      return <span>{value}%</span>;
    }

    return <span>{value}</span>;
  }

  return (
    <div className="flex flex-col divide-y">
      <div className="hidden lg:flex justify-between">
        <h1 className="basis-1/2">Diagnóstico</h1>
        <h1 className="">Resultados da sua coop</h1>
      </div>
      {CONTENT?.map((item) => (
        <div
          key={item.id}
          className="flex flex-wrap sm:flex-nowrap items-center justify-center gap-3 py-3"
        >
          <div>
            <img src={item.icon} alt="" className="w-[48px] sm:w-[64px]" />
          </div>
          <div className="flex-1 basis-1/2 sm:basis-0">
            <h4 className="text-brand-green">{item.title}</h4>
            <p className="leading-tight text-xs sm:text-sm">
              {item.description}
            </p>
          </div>
          {data?.[item.id] ? (
            <div className="flex sm:flex-col gap-2 text-center">
              {item.kpis.map((kpi) => (
                <div
                  className="w-[155px] border rounded-2xl pt-3 pb-2 px-1 flex flex-col gap-1"
                  key={kpi.field}
                >
                  <span className="leading-none text-[0.7rem]">
                    {kpi.label}
                  </span>
                  <p className="text-2xl font-bold text-brand-blue leading-none">
                    {renderKpi(kpi, data[item.id][kpi.field])}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <Button
              type="button"
              onClick={() => handleShowMore(item.id)}
              className="rounded-full bg-brand-blue sm:w-[155px]"
            >
              Saiba mais
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}
