import { getFunifierAPIClient } from "./http";

export const apiPublicHeaders = {
  headers: {
    Authorization:
      "Basic " +
      btoa(
        `${import.meta.env.VITE_APP_APIKEY}:${
          import.meta.env.VITE_APP_REGISTRATION_SECRETKEY
        }`,
      ),
  },
};

export const funifierApi = getFunifierAPIClient();

// export const api = getAPIClient();
