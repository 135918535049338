import { useAppStore } from "@/store/app";

import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./header";

export function NavBar() {
  const { logout } = useAppStore();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = useCallback(() => {
    if (location.pathname === "/saiba-mais") {
      navigate("/");
    } else {
      logout();
      navigate("/login");
    }
  }, [logout, location, navigate]);

  return (
    <Header
      back={location.pathname === "/saiba-mais"}
      onClick={() => handleLogout()}
    />
  );
}
