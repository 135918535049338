import Eliza from "@/components/eliza";
import { Button } from "@/components/ui/button";

import LogoDiagnostico from "@/components/logo-diagnostico";
import LogoOCB from "@/components/logo-ocb";
import { useAppStore } from "@/store/app";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Welcome() {
  const { logout, appType } = useAppStore();

  useEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    document.body.classList.remove("bg-internal");
  }, []);

  return (
    <div className="absolute overflow-hidden top-0 left-0 w-full h-full flex items-center">
      <div className="text-center max-w-screen-md mx-auto w-full p-[10vw] -top-10 relative flex flex-col gap-8">
        <h1 className="text-4xl">{appType}</h1>
        <hr className="border-black" />
        <LogoDiagnostico className="w-full" />
        <Link to="/login">
          <Button className="bg-brand-blue mx-auto pt-12 pb-10 px-16 rounded-full text-4xl flex justify-center">
            <span>INICIAR</span>
          </Button>
        </Link>
      </div>
      <Eliza
        name="default"
        className="absolute h-[50vh] xl:h-[90vh] 2xl:h-[60vh] -right-10 xl:right-20 -bottom-32 xl:-bottom-48 animate-in slide-in-from-right-20"
      />
      <LogoOCB
        white
        className="absolute left-5 sm:left-0 sm:right-0 sm:mx-auto bottom-5"
      />
    </div>
  );
}
