import { useAppStore } from "@/store/app";
import { type PropsWithChildren, useEffect } from "react";

export default function ThemeProvider({ children }: PropsWithChildren) {
  const { setAppTheme } = useAppStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const theme = params.get("theme");

    if (theme) {
      setAppTheme(theme);
      document.body.classList.add(`${theme}`);
    } else {
      const hostname = window.location.hostname;
      if (hostname.includes("capacita")) {
        setAppTheme("capacitacoop");
        document.body.classList.add("capacitacoop");
      } else {
        setAppTheme("avaliacoop");
        document.body.classList.add("avaliacoop");
      }
    }

    // setAppTheme("capacitacoop");
    // document.body.classList.add("capacitacoop");
  }, [setAppTheme]);

  return children;
}
