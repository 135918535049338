import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

// import { formatarFaturamento } from "@/lib/utils";

import diagnostico from "../../assets/images/pdf/capacitacoop.png";
import logo from "../../assets/images/pdf/logo-ocb.png";

import { CONTENT } from "../dashboard/capacitacoop";

// Register font
Font.register({
  family: "Intro",
  fonts: [
    {
      src: "/fonts/Inter-Regular.ttf",
    },
    {
      src: "/fonts/Inter-Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "/fonts/Inter-ExtraBold.ttf",
      fontWeight: 900,
    },
  ],
});

const brandOrange = "#F57922";
// const brandGreen = "#6D6D11";
const brandBlue = "#171C67";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 20,
    fontFamily: "Intro",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",

    gap: 20,
    justifyContent: "center",
  },
  rowItem: {
    flexDirection: "row",
    padding: 5,
    gap: 10,
  },
  rowImage: {
    width: 52,
    height: 52,
  },
  section: {
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    flex: 1,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#333333",
  },
  results: {
    margin: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: "28%",
  },
  header: {
    marginHorizontal: 5,
    marginVertical: 10,
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "#171C67",
    fontSize: 26,
    fontWeight: 900,
  },
  kpiTitle: {
    fontSize: 16,
    textAlign: "center",
  },
  kpiValue: {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
    color: brandOrange,
  },
  kpiItem: {
    margin: 2,
    padding: 10,
    borderWidth: 1,
    borderColor: brandOrange,
    borderStyle: "solid",
    borderRadius: 13,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    gap: 5,
  },
});

type CapacitaCoopDocumentProps = {
  data: any;
};

// Create Document Component
const CapacitaCoopDocument = ({ data }: CapacitaCoopDocumentProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            paddingTop: 20,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={diagnostico}
            style={{
              height: "48px",
            }}
          />
        </View>
        <View style={styles.header}>
          <Text>{data.coop}</Text>
        </View>

        {CONTENT?.map((item) => (
          <View key={item.id} style={styles.row}>
            {item.kpis.map((kpi) => (
              <View key={kpi.field} style={styles.kpiItem}>
                <View style={styles.rowImage}>
                  <Image src={kpi.iconPdf} />
                </View>
                <Text style={styles.kpiTitle}>{kpi.label}</Text>
                <Text style={styles.kpiValue}>
                  {data[item.id] ? data[item.id][kpi.field] : 0}
                </Text>
              </View>
            ))}
          </View>
        ))}
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 15,
            padding: 30,
          }}
        >
          <Text style={{ fontWeight: "bold", color: brandBlue, fontSize: 24 }}>
            A CapacitaCoop é aliada no desenvolvimento contínuo das
            cooperativas.
          </Text>
          <Text>
            Com mais de 200 cursos online, a plataforma oferece o conhecimento
            necessário para impulsionar o sucesso e a eficiência das
            cooperativas brasileiras.
          </Text>
          <Text>
            De temas de governança e gestão à adoção de tecnologias inovadoras,
            os cursos são desenvolvidos para capacitar as equipes, melhorar os
            resultados da organização e criar impacto positivo na comunidade.
          </Text>
        </View>
        <View
          style={{
            margin: 10,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link href="https://somos.coop.br/">
            <Image
              src={logo}
              style={{
                width: "180px",
              }}
            />
          </Link>
        </View>
      </Page>
    </Document>
  );
};

export default CapacitaCoopDocument;
